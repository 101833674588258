/* .area{
    background: linear-gradient(to  bottom,
    white,
     rgba(210,213,219,0.9),
     #ACEFD1 );
    background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);
     width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

}
p {
	position: relative;
	max-width: 30em;

    background-color: #fff;
    opacity: .67;
	padding: 1.125em 1.5em;
	font-size: 1.25em;
	border-radius: 1rem;
  box-shadow:	0 0.125rem 0.5rem rgba(0, 0, 0, .2), 0 0.0625rem 0.125rem rgba(0, 0, 0, .2);
}

p::before {
	content: '';
	position: absolute;
	width: 0;
	height: 0;
	bottom: 100%;
	left: 1.5em;
	border: .75rem solid transparent;
	border-top: none;

	border-bottom-color: #fff;
	filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, .1));
}

.Subtitle {

    color: #FFFFFF;
    font-family: Montserrat;
    font-size: 2em;
    font-style: italic;
    font-weight: 300;
    line-height: 22px;
    text-align: center;
  }
input{
    padding: 15px;
    height: 4vh;
    margin-top: 5vh;
    font-size: 19px;
    width: 40vw;
     border: none;
     outline: none;
    border-radius: 5rem;
} */

.circles{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 98vh;
    overflow: hidden;
}

.circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 25s linear infinite;
    bottom: 0px;

}

.circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}


.autocomplete-dropdown-container {
  border-bottom: honeydew;
  border-left: honeydew;
  width: 37.5vw;
  padding: 10px;
  border-right: honeydew;
  /* border-top: 1px solid #e6e6e6; */
  /* box-shadow: 0 2px 4px rgba(0,0,0,0.2); */
  position: absolute;
  /* bottom: 30%; */
  z-index: 1000;
  border-radius: 0 0 10px 10px;
}

.suggestion-item {
    padding: 2%;
}

.suggestion-item--active{
    padding: 2%;

}


.circles li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}



@keyframes animate {

    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}




@media (max-width: 992px) {
  .divImgWelcomePage {
    display: none;
  }
}



#root>div>div {
background-color: white!important;
}

@media (max-width: 992px) {
  #colImgLeft {
    display: none;
  }
}

.containerEstimation {
  max-width: 710px;
  min-height: 100vh;
  /* background-color: #f6f6f6; */
}

@media (min-width: 992px) {
  .containerEstimation {

    transform-origin: center top;
  }
}
/*
@media (min-width: 992px) and (min-height: 800px){
  .containerEstimation {
    margin-top: 30px;
    transform: scale(1.32107);
    transform: scale(1.3);
    transform-origin: center top;
  }
} */

.cardChat {
  border: none!important;
  /* OLD
  background-color: #f6f6f6!important;
  background-color: #f6f6f5!important;*/
  background-color: #ffffff!important;
}

.cardChatBody {
  display: inline-block;
  font-family: SourceSansPro,sans-serif;
  /* OLD
  border-radius: 15px;
  background-color: #5a94e7;
  color: white;*/
  border-radius: 0px 20px 20px 20px;
  background-color: rgba(255, 206, 0, 0.6);
  color: #515151;
  font-weight: 400;
  line-height: 1.5;
  font-size: 1.125rem;
}

@media (max-width: 992px) {
  .cardChatBody {
    font-size: 16px;
  }
}

.colCardChatBody {
  align-self: center;
}

.cardChatImg {
  /* OLD
  border: 3px solid #5a94e7;
  height: 81px;
  width: 81px!important;*/
  height: 54px;
  width: 54px!important;
  border-radius: 100%!important;
  object-fit: cover;
  border: 3px solid #FFCE00;
}

@media (max-width: 992px) {
  .cardChatImg {
    width: 54px!important;
    height: 54px;
  }
}

.colCardChatImg {
  /* OLD
  justify-content: right;*/
  display: flex;
  justify-content: center;
  padding-right: 0!important;
}

.containerCard {
  display: block;
  flex: 0 0 calc(33.3333% - 18px);
  min-width: calc(33.3333% - 18px);
  margin: 2px 9px;
  position: relative;
  box-sizing: border-box;
}

.containerCardBV {
  display: block;
  flex: 0 0 calc(33.3333% - 18px);
  min-width: calc(33.3333% - 18px);
  /* margin: 2px 9px; */
  position: relative;
  box-sizing: border-box;
}

@media (max-width: 374px){
  .containerCardBV {
    flex: 0 0 calc(100% - 19px)!important;
    min-width: calc(10% - 19px)!important;
  }
}

@media (max-width: 992px) {
  .containerCardBV {
    flex: 0 0 calc(50% - 19px);
    min-width: calc(50% - 19px);
  }
}

@media (max-width: 992px) {
  .containerCard {
    flex: 0 0 calc(50% - 19px);
    min-width: calc(50% - 19px);
  }
}

@media (min-width: 992px){
  .containerCard {
    /*max-width: 188px;*/
  }
}

@media (max-width: 374px){
  .colBV {
    flex: 0 0 calc(100% - 19px) !important;
    min-width: calc(10% - 19px);
  }
}

.labelCard {
  display: block;
  cursor: pointer;
  margin-bottom: 0.5rem;
  box-sizing: border-box;
}

.inputCard {
  box-sizing: border-box;
  padding: 0;
  position: absolute;
  z-index: -1;
  opacity: 0;
  overflow: visible;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  background-color: initial;
  cursor: default;
  appearance: auto;
  border: initial;
}

.container2Card {
  border-radius: 10px;
  box-shadow: 0 0 9px 0 rgb(0 0 0 / 17%);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 15px 9px 6px;
  position: relative;
  border: 1px solid transparent;
  box-sizing: border-box;
  cursor: pointer;
}

@media (max-width: 992px) {
  .container2Card {
    min-height: 100px;
  }
}

@media (min-height: 760px) {
  .container2Card {
    min-height: 157px;
  }
}

.okCard {
  position: absolute;
  display: inline-block;
  top: 7px;
  right: 10px;
  z-index: 3;
  font-size: 1.125rem;
}

.colorOkCard {
  color: #00c695;
}

.containerImgCard {
  height: 50px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}

@media (max-width: 992px) {
  .containerImgCard {
    height: 45px;
  }
}

@media (min-height: 760px) {
  .containerImgCard {
    height: 70px;
  }
}

.imgCard {
  height: 100%;
  object-fit: contain;
  max-width: 100%;
  vertical-align: middle;
  border-style: none;
  box-sizing: border-box;
}

.titleCard {
  font-size: .9375rem;
  font-weight: 600;
  line-height: 1.25;
  text-align: center;
  color: #4e4e61;
  margin-bottom: 0;
  margin-top: 4px;
  flex: 1;
}

.spanTitleCard {
  display: block;
}

.labelNb {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  font-size: .9357rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  text-align: right;
  color: #272728;
}


.containerCases {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.case {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 40px;
  background-color: white;
  color: grey;
  border: 1px solid grey;
  border-radius: 30%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.case.active {
  background-color: #00A0EB;
  border: 1px solid #00A0EB;
  color: white;
}


.containerPlusMoins {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.iconPlusMoins {
  height: 28px;
  max-width: 28px;
  border-radius: 50%!important;
  font-size: .7rem!important;
  background-color: white!important;
  color: #5a94e7!important;
}

.inputPlusMoins {
  margin: 0 4px;
  font-size: 0.87rem!important;
  font-weight: 600!important;
  text-align: center;
  color: #272728!important;
  border: 1px solid #e0e0e0!important;
  box-shadow: none;
  padding: 3px!important;
  border-radius: 15px!important;
  min-width: 47px;
  width: calc(100% - 68px)!important;
  max-width: calc(100% - 115px);
  height: 32px;
}

.rowNextButton {
  display: flex!important;
  justify-content: center;
}

.nextButton {
  text-transform: none!important;
  border-radius: 20px!important;
  /* OLD
  background-color: #00c695!important;
  border-color: #00c695!important;*/
  background-color: #00A0EB!important;
  border-color: #00A0EB!important;
  font-size: 1.5rem!important;
  font-weight: 700!important;
  box-shadow: 0 0 17px 0 rgb(0 0 0 / 26%)!important;
  margin-top: 4vh!important;
  max-width: 50%!important;
  padding: 11px 0!important;
}

.selectDecennie {
  width: 70%!important;
  height: 50px;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 15%;
  border-radius: 4px!important;
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 17%);
  background-color: #fff!important;
}

.errorDecennie {
  font-size: 0.800rem;
  color: red;
}

@media (max-width: 992px) {
  .colGroupCardLeft {
    padding-left: 0!important;
  }
}

@media (max-width: 992px) {
  .colGroupCardRight {
    padding-right: 0!important;
  }
}

@media (max-width: 992px) and (min-width: 576px) {
  .colGroupCardLeftt {
    /* padding-left: 0!important; */
  }
}

@media (max-width: 992px) and (min-width: 576px) {
  .colGroupCardRightt {
    /* padding-right: 0!important; */
  }
}

.interestedButton {
  font-size: 1.5rem!important;
  border-color: #00c695!important;
  border-width: 2px!important;
  border-radius: 15px!important;
  text-transform: none!important;
  color: #00c695!important;
  box-shadow: none;
  font-weight: 700!important;
  margin-left: 60px!important;
  margin-right: 60px!important;
  margin-top: 20px!important;
  height: 70px;
  white-space: nowrap;
}

.yesNoButton {
  font-size: 1.5rem!important;
  border-color: #00c695!important;
  border-width: 2px!important;
  border-radius: 15px!important;
  text-transform: none!important;
  color: #00c695!important;
  box-shadow: none;
  font-weight: 700!important;
  margin-left: 60px!important;
  margin-right: 60px!important;
  margin-top: 20px!important;
  height: 70px;
  padding: 2vh 7vh !important;
}

.intentionButton {
  width: 100%!important;
  padding: 17px 10px!important;
  border-radius: 10px!important;
  box-shadow: 0 0 9px 0 rgb(0 0 0 / 17%);
  background-color: #fff!important;
  font-size: 1rem!important;
  font-weight: 600!important;
  line-height: 1.38!important;
  text-align: center;
  color: #4e4e61!important;
  border: none!important;
  width: 100%;
  height: 100%;
  text-transform: none!important;
  font-family: SourceSansPro,sans-serif!important;
}

.receiveButton {
  padding-left: 18px!important;
  padding-right: 18px!important;
  font-size: 1.2rem!important;
  background-color: #00A0EB!important;
  border-color: #00A0EB!important;
  line-height: 1.13!important;
  font-weight: 700!important;
  padding-top: 15.5px!important;
  padding-bottom: 15.5px!important;
  border-radius: 15px!important;
  box-shadow: 0 0 17px 0 rgb(0 0 0 / 26%)!important;
  max-width: 450px!important;
}


.example-exit {
  opacity: 1;
}
.example-exit-active {
  opacity: 0.01;
  transition: opacity 5000ms ease-in;
}








@media (max-width: 992px) {
  #titleWelc {
    margin-top:75px;
    font-size: 1.8em;
  }
  .MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root.css-2zs1s2 {
    width: 60vw!important;
}
img.jss5 {
    width: 40px;
    height: 40px;
}
#btnGo {
  margin-left: 20%!important;
}
#btnGo button {
  width:70%!important;
}
}

@media (max-width: 500px) {
#btnGo {
  margin-left: 0%!important;
}
#btnGo button {
  width:100%!important;
}
}


